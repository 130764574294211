import React from 'react';

const NotebookViewer_2 = () => {
  return (
    <iframe
      src="/html/campaign-exploration-1.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Notebook Viewer"
    ></iframe>
  );
};

export default NotebookViewer_2;
