import React, { useEffect } from "react";

function CloudLogin() {
    useEffect(() => {
        window.location.replace("https://cloud.connerjordan.com");
    }, []);

    return null;
}

export default CloudLogin;


 